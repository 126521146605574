import firebase from '../config/firebase';
import api from './_https';

const UserService = {
  signin: async (email, password) => {
    const auth = firebase.auth();
    const credential = await auth.signInWithEmailAndPassword(email, password);
    const me = await UserService.getMe();
    if (me.type !== 'ADMIN') {
      auth.signOut();
      throw { code: 'auth/invalid-user-type' };
    }
    return credential;
  },
  getMe: async () => {
    const response = await api.get('/msvc/v1/users/me');
    if (response.ok && response.data) {
      return response.data;
    }
  },
};

export default UserService;
