import React from 'react';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function LoadingIndicator({ fullScreen = false, width, height }) {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width={fullScreen ? '100vw' : width}
      height={fullScreen ? '100vh' : height}
    >
      <CircularProgress />
    </Box>
  );
}
