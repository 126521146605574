import React from 'react';

export default function BarChartIcon() {
  return (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ display: 'block', margin: 'auto' }}
    >
      <path
        d="M14.3662 13.4697H16.3008C17.3262 13.4697 17.8799 12.9365 17.8799 11.959V2.11523C17.8799 1.13086 17.3262 0.597656 16.3008 0.597656H14.3662C13.3408 0.597656 12.7871 1.13086 12.7871 2.11523V11.959C12.7871 12.9365 13.3408 13.4697 14.3662 13.4697ZM8.0293 13.4697H9.96387C10.9893 13.4697 11.543 12.9365 11.543 11.959V4.10449C11.543 3.12695 10.9893 2.58691 9.96387 2.58691H8.0293C7.00391 2.58691 6.45703 3.12695 6.45703 4.11133V11.959C6.45703 12.9365 7.00391 13.4697 8.0293 13.4697ZM1.69238 13.4697H3.62695C4.65918 13.4697 5.21289 12.9365 5.21289 11.959V6.10059C5.21289 5.12305 4.65918 4.58301 3.62695 4.58301H1.69238C0.673828 4.58301 0.120117 5.12305 0.120117 6.10742V11.959C0.120117 12.9365 0.673828 13.4697 1.69238 13.4697Z"
        fill="white"
      />
    </svg>
  );
}
