import React from 'react';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import { Spinner, ButtonStyles } from './index';

export default function PrimaryButton({
  loading,
  children,
  width,
  minWidth,
  height,
  variant = 'contained',
  buttonClasses,
  ...props
}) {
  const classes = ButtonStyles();
  return (
    <Box width={width} minWidth={minWidth} height={height}>
      <Button
        variant={variant}
        color="primary"
        disableRipple={true}
        className={clsx(classes.buttonLabel, { [classes.textPrimaryButton]: variant === 'text' }, buttonClasses)}
        {...props}
      >
        {loading ? <Spinner /> : children}
      </Button>
    </Box>
  );
}
