/* eslint-disable mui-unused-classes/unused-classes */
import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, withStyles } from '@material-ui/core/styles';

export const Spinner = withStyles({
  root: {
    marginRight: 10,
    color: '#fff',
    margin: '4px 0',
  },
})(({ classes }) => <CircularProgress className={classes.root} size={20} />);

export const ButtonStyles = makeStyles((theme) => ({
  buttonLabel: {
    fontWeight: '400',
    fontSize: '16px',
    '&:hover': {
      backgroundColor: theme.palette.primary.mainLight,
    },
  },
  textPrimaryButton: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.mainLight,
      backgroundColor: 'transparent',
    },
  },
  successButton: {
    background: theme.palette.success.main,
    color: '#ffffff',
    '&:hover': {
      background: theme.palette.success.light,
    },
  },
  successTextButton: {
    background: 'transparent',
    color: theme.palette.success.main,
    '&:hover': {
      color: theme.palette.success.light,
      background: 'transparent',
    },
  },
  dangersButton: {
    background: theme.palette.danger.main,
    color: '#ffffff',
    '&:hover': {
      background: theme.palette.danger.dark,
    },
  },
  dangersTextButton: {
    background: 'transparent',
    color: theme.palette.danger.main,
    '&:hover': {
      color: theme.palette.danger.dark,
      background: 'transparent',
    },
  },
}));

export { default as PrimaryButton } from './PrimaryButton';
export { default as SuccessButton } from './SuccessButton';
export { default as DangerButton } from './DangerButton';
