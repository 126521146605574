import React from 'react';

export default function VideoCameraIcon() {
  return (
    <svg
      width="21"
      height="14"
      viewBox="0 0 21 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ display: 'block', margin: 'auto' }}
    >
      <path
        d="M3.50781 13.7891H11.7266C13.4141 13.7891 14.3828 12.8438 14.3828 11.1875V3.51562C14.3828 1.85938 13.4766 0.914062 11.7891 0.914062H3.50781C1.88281 0.914062 0.851562 1.85938 0.851562 3.51562V11.2422C0.851562 12.8906 1.82812 13.7891 3.50781 13.7891ZM15.5312 9.86719L18.2031 12.1641C18.5234 12.4453 18.8672 12.625 19.1953 12.625C19.8281 12.625 20.2578 12.1719 20.2578 11.4844V3.25781C20.2578 2.57031 19.8281 2.11719 19.1953 2.11719C18.8594 2.11719 18.5234 2.29688 18.2031 2.57812L15.5312 4.875V9.86719Z"
        fill="white"
      />
    </svg>
  );
}
