import { lazy } from 'react';

const CustomerTableManagement = lazy(() => import('pages/UserManagement/Table/CustomerTable'));
const InterpreterTableManagement = lazy(() => import('pages/UserManagement/Table/InterpreterTable'));
const AdminTaleManagement = lazy(() => import('pages/UserManagement/Table/AdminTable'));

const InterpreterSchedule = lazy(() => import('pages/interpreterSchledule'));
const CallManagement = lazy(() => import('pages/CallManagement'));
const CustomersReport = lazy(() => import('pages/Report/CustomersReport'));
const InterpreterReport = lazy(() => import('pages/Report/InterpreterReport'));
const Analytics = lazy(() => import('pages/Analytics'));
const CallConfiguration = lazy(() => import('pages/Configuration/CallConfiguration'));
const QueueConfiguration = lazy(() => import('pages/Configuration/QueueConfiguration'));
const InterpreterConfiguration = lazy(() => import('pages/Configuration/InterpreterConfiguration'));
const HelpRequestConfiguration = lazy(() => import('pages/Configuration/HelpRequestConfiguration'));
const OrganizationTableManagement = lazy(() => import('pages/Organization/index'));
const OrganizationTableDetail = lazy(() => import('pages/Organization/Table/OrganizationDetailTable'));

const UpdateAdminProfile = lazy(() => import('components/Form/UpdateAdminProfile/index'));

export const routesPath = {
  callManagement: '/',

  userManagementCustomer: '/user-management/customers',
  userManagementInterpreter: '/user-management/interpreter',
  userManagementAdmin: '/user-management/admin',

  interpreterSchedule: '/interpreter-schedule',
  customersReport: '/report/customers',
  interpreterReport: '/report/interpreters',
  analytics: '/analytics',
  updateAdminProfile: '/profile',

  callSetting: '/configuration/call-setting',
  interpreterSetting: '/configuration/interpreter-setting',
  queueSetting: '/configuration/queue-setting',
  helpRequestSetting: '/configuration/help-request-setting',

  organizationTable: '/organisation',
  organizationTableDetail: '/organisation/detail/',
};

export const routes = [
  { path: routesPath.callManagement, component: CallManagement },
  { path: routesPath.userManagementCustomer, component: CustomerTableManagement },
  { path: routesPath.userManagementInterpreter, component: InterpreterTableManagement },
  { path: routesPath.userManagementAdmin, component: AdminTaleManagement },

  { path: routesPath.interpreterSchedule, component: InterpreterSchedule },
  { path: routesPath.customersReport, component: CustomersReport },
  { path: routesPath.interpreterReport, component: InterpreterReport },

  { path: routesPath.analytics, component: Analytics },
  { path: routesPath.updateAdminProfile, component: UpdateAdminProfile },

  { path: routesPath.callSetting, component: CallConfiguration },
  { path: routesPath.interpreterSetting, component: InterpreterConfiguration },
  { path: routesPath.queueSetting, component: QueueConfiguration },
  { path: routesPath.helpRequestSetting, component: HelpRequestConfiguration },

  { path: routesPath.organizationTable, component: OrganizationTableManagement },
  { path: `${routesPath.organizationTableDetail}:organizationId?`, component: OrganizationTableDetail },
];
