import React from 'react';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';

import sideMenuItem from './SideMenuItem';
import Link from 'components/Link/LLLink';

const useStyles = makeStyles((theme) => ({
  navLinkLabel: {
    color: '#fff',
    textDecoration: 'none',
    zIndex: 5,
    margin: '20px 0',
  },
  listItemIcon: {
    paddingLeft: '10px',
  },
  activeNav: {
    background: theme.palette.background.darkLighter,
    zIndex: 4,
    color: '#fff',
    '&:hover': {
      background: theme.palette.background.darkLighter,
    },
  },
  hoverNav: {
    '&:hover': {
      background: theme.palette.background.darkLighter,
    },
  },
  activeNavBorderLeft: {
    position: 'absolute',
    '&:after': {
      content: "''",
      position: 'absolute',
      borderLeft: '3px solid #fff',
      right: 12,
      bottom: -26,
      height: '53px',
    },
  },
}));

export default function SideNav({ setPageTitle, handleClick }) {
  const classes = useStyles();
  const location = useLocation();
  const splitPathname = location.pathname?.split('/')[1];

  const activeRoute = (route) => {
    if (route === location.pathname) {
      return true;
    } else if (route.includes(splitPathname) || location.pathname.includes(splitPathname)) {
      if (route?.split('/')[1] === location.pathname?.split('/')[1]) {
        return true;
      }
    }
  };
  return (
    <List>
      {sideMenuItem.map((item, index) => (
        <Link
          key={index}
          to={item.href}
          className={clsx(classes.navLinkLabel)}
          onClick={() => {
            setPageTitle(item.label);
            handleClick(item.subMenu);
          }}
        >
          <ListItem button disableRipple className={activeRoute(item.href) ? classes.activeNav : classes.hoverNav}>
            {activeRoute(item.href) && <div className={classes.activeNavBorderLeft}> </div>}
            <ListItemIcon className={clsx(classes.listItemIcon)}>
              <Box>{item.icon}</Box>
            </ListItemIcon>
            <ListItemText disableTypography primary={item.label} />
          </ListItem>
        </Link>
      ))}
    </List>
  );
}
