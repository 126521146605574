import { createContext, useContext } from 'react';
import useFirebaseAuth from './useFirebaseAuth';

const StateContext = createContext();

export const useAppState = () => {
  return useContext(StateContext);
};

export function AppStateProvider({ children }) {
  let contextValue = {};

  contextValue = { ...contextValue, ...useFirebaseAuth() };

  return <StateContext.Provider value={{ ...contextValue }}>{children}</StateContext.Provider>;
}
