import React from 'react';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import { Spinner, ButtonStyles } from './index';

export default function DangerButton({
  loading,
  children,
  buttonClasses,
  width,
  height,
  variant = 'contained',
  ...props
}) {
  const classes = ButtonStyles();
  return (
    <Box width={width} height={height}>
      <Button
        variant={variant}
        disableRipple={true}
        className={clsx(
          classes.buttonLabel,
          { [classes.dangersTextButton]: variant === 'text' },
          classes.dangersButton,
          buttonClasses
        )}
        {...props}
      >
        {loading ? <Spinner /> : children}
      </Button>
    </Box>
  );
}
