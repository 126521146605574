import React from 'react';
import ReactDOM from 'react-dom';
import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

import LLAdminLayout from 'components/Layout/LLAdminLayout';
import SignInForm from 'components/Form/SignInForm';
import PrivateRoute from 'components/PrivateRoute/PrivateRoute';
import { DrawerProvider } from 'context/useDrawerContext';
import { NotificationProvider } from 'context/useNotificationContext';
import { AppStateProvider } from 'state';
import theme from './theme';
import reportWebVitals from './reportWebVitals';
import { routes } from './routes';
import './index.css';
import './wdyr';

ReactDOM.render(
  <React.StrictMode>
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <AppStateProvider>
          <NotificationProvider>
            <DrawerProvider>
              <Switch>
                {routes.map(({ path, component }, idx) => (
                  <PrivateRoute key={idx} exact path={path}>
                    <LLAdminLayout content={component} />
                  </PrivateRoute>
                ))}
                <Route exact path="/sign-in">
                  <SignInForm />
                </Route>
                <Redirect to="/" />
              </Switch>
            </DrawerProvider>
          </NotificationProvider>
        </AppStateProvider>
      </Router>
    </MuiThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
