import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { useAppState } from 'state';

export default function PrivateRoute({ children, ...rest }) {
  const { user, isAuthReady } = useAppState();
  const renderChildren = user;

  if (!renderChildren && !isAuthReady) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        renderChildren ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/sign-in',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
