import apisauce from 'apisauce';
import qs from 'qs';
import firebase from 'firebase';

const baseURL = process.env.REACT_APP_BASE_URL;

export const api = apisauce.create({
  baseURL,
});

api.addAsyncRequestTransform(async (request) => {
  let headers = request.headers;

  const token = await firebase.auth().currentUser?.getIdToken();
  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }
  if (!headers['Content-Type']) {
    headers['Content-Type'] = 'application/json';
  }
  request.headers = headers;
});

api.addResponseTransform((response) => {
  const { status } = response;
  if (status === 403 || status === 401) {
    firebase.auth().signOut();
  }
});

export default {
  get: async (url, params, config) => api.get(url, params, config),
  del: async (url) => api.delete(url, null),

  put: async (url, body, headers) => api.put(url, qs.stringify(body, { encode: true }), headers),
  putFile: async (url, body, headers) => api.put(url, body, headers),

  putJson: async (url, body, headers) => api.put(url, JSON.stringify(body), headers),

  post: async (url, body, headers) => api.post(url, qs.stringify(body, { encode: true }), headers),
  postFile: async (url, body, headers) => api.post(url, body, headers),

  postJson: async (url, body, headers) => api.post(url, JSON.stringify(body), headers),
};
