import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

export const useLinkStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
  },
}));

export default function LLLink({ openInNewTab, to, target, rel, classNames, children, ...props }) {
  const classes = useLinkStyles();
  return (
    <>
      {openInNewTab && (
        <a href={to} target={target} rel={rel} className={clsx(classes.root, classNames)} {...props}>
          {children}
        </a>
      )}

      {!openInNewTab && (
        <Link to={to} className={clsx(classes.root, classNames)} {...props}>
          {children}
        </Link>
      )}
    </>
  );
}
