import { routesPath } from 'routes';

export default function handleGetPageTitleOnPageLoad() {
  const pagePathname = window.location.pathname;

  // Using for dynamic link
  if (pagePathname.includes(routesPath.organizationTable)) return 'Organisation';

  switch (pagePathname) {
    case routesPath.callManagement:
      return 'Call Management';

    case routesPath.userManagementCustomer:
    case routesPath.userManagementInterpreter:
    case routesPath.userManagementAdmin:
      return 'User managements';

    case routesPath.organizationTable:
      return 'Organisation';

    case routesPath.interpreterSchedule:
      return 'Interpreter’s schedule';

    case routesPath.callSetting:
    case routesPath.interpreterSetting:
    case routesPath.queueSetting:
    case routesPath.helpRequestSetting:
      return 'Configuration';

    case routesPath.customersReport:
    case routesPath.interpreterReport:
      return 'Report';

    case routesPath.analytics:
      return 'Analytics';

    default:
      break;
  }
}
