import React, { useState, createContext } from 'react';

export const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [snackbarState, setSnackbarState] = useState({ open: '', status: '', message: '' });
  const [alertState, setAlertState] = useState(null);

  return (
    <NotificationContext.Provider value={{ snackbarState, setSnackbarState, alertState, setAlertState }}>
      {children}
    </NotificationContext.Provider>
  );
};
