import React from 'react';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { makeStyles } from '@material-ui/core/styles';

import CheckIcon from 'assets/Icons/CheckIcon';
import ErrorWhiteIcon from 'assets/Icons/ErrorWhiteIcon';

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: '20px',
    minWidth: '260px',
    fontSize: '15px',
    fontWeight: '400',
  },
  successBackground: {
    background: theme.palette.background.dark,
  },
  errorBackground: {
    background: theme.palette.danger.main,
  },
}));

export default function LLSnackbar({ snackbarState, setSnackbarState }) {
  const classes = useStyles();
  const { open, status, message } = snackbarState;

  const handleClose = () => {
    setSnackbarState({ open: false });
  };

  return (
    <Snackbar
      key={{ vertical: 'top', horizontal: 'right' }}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={Boolean(open)}
      autoHideDuration={4000}
      onClose={handleClose}
    >
      <SnackbarContent
        className={clsx(
          classes.content,
          { [classes.successBackground]: status === 'success' },
          { [classes.errorBackground]: status === 'error' }
        )}
        message={
          <Box display="flex">
            {status === 'success' && <CheckIcon />}
            {status === 'error' && <ErrorWhiteIcon />}
            <Box paddingX="20px">{message}</Box>
          </Box>
        }
      />
    </Snackbar>
  );
}
