import React from 'react';
import clsx from 'clsx';
import { Box, Typography, makeStyles } from '@material-ui/core';

import logo from 'assets/Logo/360Live-192.png';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    background: theme.palette.background.whiteSmoke,
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  logo: {
    textAlign: 'center',
    maxHeight: 126,
  },
  paper: {
    height: 'auto',
    width: '390px',
    borderRadius: '8px',
    boxShadow: theme.palette.shadow.main,
    background: theme.palette.background.white,
    padding: theme.spacing(4),
  },
  title: {
    fontWeight: '600',
    fontSize: '24px',
  },
}));

export default function LoginPageLayout({ title, children, footer }) {
  const classes = useStyles();
  return (
    <section className={clsx(classes.root, classes.center)}>
      <Box m={2}>
        <img src={logo} alt="360Live logo" className={classes.logo} />
      </Box>
      <div className={clsx(classes.paper, classes.center)}>
        <Box mb={2} display="flex" alignItems="center">
          <Typography className={classes.title}>{title}</Typography>
        </Box>
        {children}
      </div>
      {footer}
    </section>
  );
}
