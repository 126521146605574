import React from 'react';

export default function FileIcon() {
  return (
    <svg
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ display: 'block', margin: 'auto' }}
    >
      <path
        d="M2.83594 17.8281H11.1641C12.8438 17.8281 13.7109 16.9453 13.7109 15.2578V8.26562H7.85156C6.875 8.26562 6.40625 7.79688 6.40625 6.82031V0.898438H2.83594C1.16406 0.898438 0.289062 1.78125 0.289062 3.47656V15.2578C0.289062 16.9453 1.16406 17.8281 2.83594 17.8281ZM8 7.125H13.6328C13.5859 6.76562 13.3281 6.42969 12.9219 6.01562L8.64844 1.6875C8.25781 1.28906 7.90625 1.02344 7.54688 0.976562V6.67188C7.55469 6.97656 7.70312 7.125 8 7.125ZM3.96875 11.7266C3.63281 11.7266 3.39062 11.4844 3.39062 11.1719C3.39062 10.8594 3.63281 10.6172 3.96875 10.6172H10.0547C10.375 10.6172 10.625 10.8594 10.625 11.1719C10.625 11.4844 10.375 11.7266 10.0547 11.7266H3.96875ZM3.96875 14.6562C3.63281 14.6562 3.39062 14.4141 3.39062 14.1016C3.39062 13.7891 3.63281 13.5469 3.96875 13.5469H10.0547C10.375 13.5469 10.625 13.7891 10.625 14.1016C10.625 14.4141 10.375 14.6562 10.0547 14.6562H3.96875Z"
        fill="white"
      />
    </svg>
  );
}
