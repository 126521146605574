import React from 'react';

export default function PowerOffIcon() {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ display: 'block', margin: 'auto' }}
    >
      <path
        d="M7.5 8.26953C8.10156 8.26953 8.49805 7.8457 8.49805 7.20996V1.44043C8.49805 0.804688 8.10156 0.380859 7.5 0.380859C6.89844 0.380859 6.49512 0.804688 6.49512 1.44043V7.20996C6.49512 7.8457 6.89844 8.26953 7.5 8.26953ZM7.5 15.3105C11.4717 15.3105 14.7393 12.043 14.7393 8.07129C14.7393 6.05469 13.8984 4.27051 12.6885 3.06055C11.7725 2.11035 10.3711 3.25195 11.335 4.30469C12.292 5.26855 12.8799 6.59473 12.8799 8.07129C12.8867 11.0518 10.4805 13.4512 7.5 13.4512C4.5127 13.4512 2.12012 11.0518 2.12012 8.07129C2.12012 6.58789 2.70801 5.26172 3.6582 4.29785C4.62207 3.25195 3.2207 2.10352 2.31152 3.05371C1.09473 4.26367 0.253906 6.05469 0.253906 8.07129C0.253906 12.043 3.52148 15.3105 7.5 15.3105Z"
        fill="#001928"
      />
    </svg>
  );
}
