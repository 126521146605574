import React from 'react';

export default function CheckIcon() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ display: 'block', margin: 'auto' }}
    >
      <path
        d="M5.48389 13.2451C5.87207 13.2451 6.17236 13.084 6.38477 12.769L13.2695 2.04639C13.4233 1.80469 13.4893 1.59961 13.4893 1.40186C13.4893 0.881836 13.123 0.522949 12.5957 0.522949C12.2368 0.522949 12.0171 0.654785 11.7974 0.999023L5.44727 11.0698L2.18799 6.8877C1.96826 6.60205 1.74121 6.48486 1.41895 6.48486C0.891602 6.48486 0.510742 6.8584 0.510742 7.37109C0.510742 7.59814 0.591309 7.81787 0.781738 8.04492L4.57568 12.7764C4.83203 13.0986 5.10303 13.2451 5.48389 13.2451Z"
        fill="white"
      />
    </svg>
  );
}
