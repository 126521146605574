import React from 'react';

export default function PeoplesIcon() {
  return (
    <svg
      width="22"
      height="15"
      viewBox="0 0 22 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ display: 'block', margin: 'auto' }}
    >
      <path
        d="M15.0859 7.28906C16.7969 7.28906 18.2422 5.77344 18.2422 3.8125C18.2422 1.89844 16.7891 0.429688 15.0859 0.429688C13.3906 0.429688 11.9297 1.92188 11.9375 3.82812C11.9375 5.77344 13.375 7.28906 15.0859 7.28906ZM6.05469 7.46875C7.54688 7.46875 8.80469 6.14062 8.80469 4.4375C8.80469 2.76562 7.53125 1.49219 6.05469 1.49219C4.57812 1.49219 3.30469 2.78906 3.3125 4.45312C3.3125 6.14062 4.57031 7.46875 6.05469 7.46875ZM1.75 14.5625H7.60938C6.77344 13.3672 7.67188 11.0078 9.4375 9.61719C8.57812 9.0625 7.46094 8.65625 6.05469 8.65625C2.5625 8.65625 0.304688 11.2266 0.304688 13.3438C0.304688 14.1172 0.695312 14.5625 1.75 14.5625ZM10.2188 14.5547H19.9531C21.2422 14.5547 21.6875 14.1641 21.6875 13.4453C21.6875 11.4375 19.1328 8.67188 15.0859 8.67188C11.0312 8.67188 8.48438 11.4375 8.48438 13.4453C8.48438 14.1641 8.92969 14.5547 10.2188 14.5547Z"
        fill="white"
      />
    </svg>
  );
}
