import React from 'react';

export default function BuildingIcon() {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ display: 'block', margin: 'auto' }}
    >
      <path
        d="M0 0.416992V17.7503H7.33333V15.0837H8.66667V17.7503H16V0.416992H0ZM1.33333 1.75033H14.6667V16.417H10V13.7503H6V16.417H1.33333V1.75033ZM2.66667 3.08366V4.41699H5.33333V3.08366H2.66667ZM6.66667 3.08366V4.41699H9.33333V3.08366H6.66667ZM10.6667 3.08366V4.41699H13.3333V3.08366H10.6667ZM2.66667 5.75033V7.08366H5.33333V5.75033H2.66667ZM6.66667 5.75033V7.08366H9.33333V5.75033H6.66667ZM10.6667 5.75033V7.08366H13.3333V5.75033H10.6667ZM2.66667 8.41699V9.75033H5.33333V8.41699H2.66667ZM6.66667 8.41699V9.75033H9.33333V8.41699H6.66667ZM10.6667 8.41699V9.75033H13.3333V8.41699H10.6667ZM2.66667 11.0837V12.417H5.33333V11.0837H2.66667ZM6.66667 11.0837V12.417H9.33333V11.0837H6.66667ZM10.6667 11.0837V12.417H13.3333V11.0837H10.6667ZM2.66667 13.7503V15.0837H5.33333V13.7503H2.66667ZM10.6667 13.7503V15.0837H13.3333V13.7503H10.6667Z"
        fill="white"
      />
    </svg>
  );
}
