import React from 'react';

export default function ErrorWhiteIcon() {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ display: 'block', margin: 'auto' }}
    >
      <path
        d="M2.25049 14.7505H13.7422C14.8774 14.7505 15.6099 13.9082 15.6099 12.8755C15.6099 12.5679 15.5293 12.2529 15.3682 11.96L9.61133 1.67676C9.25977 1.03955 8.62988 0.717285 8 0.717285C7.37012 0.717285 6.73291 1.03955 6.38135 1.67676L0.631836 11.96C0.470703 12.2603 0.382812 12.5679 0.382812 12.8755C0.382812 13.9082 1.11523 14.7505 2.25049 14.7505ZM8.00732 9.71143C7.5752 9.71143 7.32617 9.46973 7.31152 9.0376L7.20898 5.37549C7.19434 4.92139 7.5166 4.60645 8 4.60645C8.46875 4.60645 8.81299 4.92871 8.79834 5.38281L8.68115 9.03027C8.6665 9.47705 8.4248 9.71143 8.00732 9.71143ZM8.00732 12.2456C7.50928 12.2456 7.09912 11.8867 7.09912 11.4033C7.09912 10.9199 7.50195 10.561 8.00732 10.561C8.50537 10.561 8.9082 10.9199 8.9082 11.4033C8.9082 11.894 8.49805 12.2456 8.00732 12.2456Z"
        fill="#fff"
      />
    </svg>
  );
}
