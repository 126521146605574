import React, { useState } from 'react';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Toolbar from '@material-ui/core/Toolbar';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import { routesPath } from 'routes';
import Popover from 'components/Popover/LLPopover';
import handleGetInitials from 'utils/handleGetInitials';
import { useAppState } from '../../state';

import PowerOffIcon from 'assets/Icons/PowerOffIcon';
import PersonIcon from 'assets/Icons/PersonIcon';

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: theme.palette.background.default,
    color: theme.palette.primary.dark,
    boxShadow: '0px 2px 8px rgba(0, 25, 40, 0.08)',
  },
  appBarShift: {
    marginLeft: 240,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '0 24px',
  },
  pageTitle: {
    fontSize: '20px',
    fontWeight: '600',
  },
  avatar: {
    cursor: 'pointer',
  },
  popover: {
    marginTop: '10px',
    marginRight: '50px',
  },
  menuItem: {
    padding: '10px 25px',
  },

  userProfileImage: {
    borderRadius: '50%',
    width: '40px',
    height: '40px',
  },
}));

export default function Header({ pageTitle, open, setHasSubMenu }) {
  const classes = useStyles();
  const history = useHistory();
  const { signOut, profile } = useAppState();
  const [isPopoverOpen, setIsPopoverOpen] = useState(null);

  const handleClick = (event) => {
    setIsPopoverOpen(event.currentTarget);
  };

  const handleClose = () => {
    setIsPopoverOpen(null);
    setHasSubMenu(false);
  };

  return (
    <AppBar
      position="fixed"
      elevation={0}
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open,
      })}
      style={{ width: `calc(100% - ${open ? '220px' : '73px'}` }}
    >
      <Toolbar disableGutters={true}>
        <Box className={classes.container}>
          <Typography noWrap className={classes.pageTitle}>
            {window.location.pathname === routesPath.updateAdminProfile ? 'Profile' : pageTitle}
          </Typography>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div style={{ cursor: 'pointer' }} onClick={handleClick}>
            {profile?.profileUrl ? (
              <img src={profile?.profileUrl} alt="profile" className={classes.userProfileImage} />
            ) : (
              <Avatar className={classes.avatar}>{handleGetInitials(profile?.fullName)}</Avatar>
            )}
          </div>
          <Popover
            handleClose={handleClose}
            isOpen={isPopoverOpen}
            className={classes.popover}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem
              className={classes.menuItem}
              onClick={() => {
                handleClose();
                history.push(routesPath.updateAdminProfile);
              }}
            >
              <Box display="flex" alignItems="center">
                <Box component="span" marginRight="15px">
                  <PersonIcon />
                </Box>
                <Box component="span">Profile</Box>
              </Box>
            </MenuItem>
            <MenuItem
              className={classes.menuItem}
              onClick={() => {
                handleClose();
                signOut();
              }}
            >
              <Box component="span" marginRight="15px">
                <PowerOffIcon />
              </Box>
              <Box component="span" fontSize="16px">
                Sign Out
              </Box>
            </MenuItem>
          </Popover>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
