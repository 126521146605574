import { routesPath } from 'routes';

import FileIcon from 'assets/Icons/FileIcon';
import ToolIcon from 'assets/Icons/ToolIcons';
import PeoplesIcon from 'assets/Icons/PeoplesIcon';
import BarChartIcon from 'assets/Icons/BarChartIcon';
import BuildingIcon from 'assets/Icons/BuildingIcon';
import VideoCameraIcon from 'assets/Icons/VideoCameraIcon';

const sideMenuItem = [
  {
    label: 'Call Management',
    href: routesPath.callManagement,
    icon: <VideoCameraIcon />,
    subMenu: [],
  },
  {
    label: 'User Management',
    href: routesPath.userManagementCustomer,
    icon: <PeoplesIcon />,
    subMenu: [
      { label: 'Customers', href: routesPath.userManagementCustomer },
      { label: 'Interpreters', href: routesPath.userManagementInterpreter },
      { label: 'Administrator', href: routesPath.userManagementAdmin },
    ],
  },
  {
    label: 'Organisation',
    href: routesPath.organizationTable,
    icon: <BuildingIcon />,
    subMenu: [],
  },
  {
    label: 'Configuration',
    href: routesPath.callSetting,
    icon: <ToolIcon />,
    subMenu: [
      { label: 'Call Settings', href: routesPath.callSetting },
      { label: 'Queue Settings', href: routesPath.queueSetting },
      { label: 'Interpreter Settings', href: routesPath.interpreterSetting },
      { label: 'Help Request Settings', href: routesPath.helpRequestSetting },
    ],
  },
  {
    label: 'Report',
    href: routesPath.customersReport,
    icon: <FileIcon />,
    subMenu: [
      { label: 'Customers', href: routesPath.customersReport },
      { label: 'Interpreters', href: routesPath.interpreterReport },
    ],
  },
  {
    label: 'Analytic',
    href: routesPath.analytics,
    icon: <BarChartIcon />,
    subMenu: [],
  },
];

export default sideMenuItem;
