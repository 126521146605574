import { useCallback, useEffect, useState } from 'react';

import firebase from '../config/firebase';
import logger from '../utils/logger';
import UserService from '../services/user';

const log = logger.withTag('useFirebaseAuth');

export default function useFirebaseAuth() {
  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState(null);
  const [isAuthReady, setIsAuthReady] = useState(false);

  const signOut = useCallback(() => {
    if (!user) return;

    log.debug('Signing out...');
    return firebase
      .auth()
      .signOut()
      .then(() => {
        setUser(null);
        setProfile(null);
        setIsAuthReady(false);
      });
  }, [user]);

  const signIn = useCallback((email, password) => {
    return UserService.signin(email, password).then((credential) => {
      setUser(credential.user);
      return credential.user;
    });
  }, []);

  useEffect(() => {
    const cancelAuthListener = firebase.auth().onIdTokenChanged(async (user) => {
      log.debug('onIdTokenChanged', user);
      if (user && user?.emailVerified) {
        setUser(user);
      } else {
        signOut();
        setUser(null);
      }
      setIsAuthReady(true);
    });

    return () => {
      cancelAuthListener();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      if (user && isAuthReady) {
        log.debug('UPDATED: ', user);
        let me = false;
        try {
          me = await UserService.getMe();
          setProfile(me);
          log.debug('user profile fetched: ', me);
        } catch (e) {
          await signOut();
        }
      }
    })();
  }, [isAuthReady, signOut, user]);

  return {
    user,
    profile,
    setProfile,
    isAuthReady,
    signIn,
    signOut,
  };
}
